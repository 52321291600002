<template>
  <v-app>
    <v-banner
        single-line
        height="400"
        class="banner"
    >
      <v-img
          src="https://h5.ophyer.cn/official_website/banner/bannerDigitalMuseum400.jpg"
          height="100%"
          :transition="false"
          @load="handleLoad"
          :class="imgLoad ? 'v-image__image--animation':''">
        <template v-slot:placeholder>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular
                indeterminate
                size="80"
                color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="pa-0 max-width-1200" style="margin-top: 150px;">
          <v-card color="transparent" flat>
            <v-card-title class="pa-0 font-size-50 font-weight-bold font-size-banner-title" >
              <span style="color:#763C18;">数字博物馆</span>
            </v-card-title>
            <v-card-title class="pa-0 mt-7 font-size-banner-tip">
              <span style="color:#763C18;">技术，让文物“跃然纸上”</span>
            </v-card-title>
            <!-- <v-card-actions class="pa-0 mt-16">
              <v-btn
                  rounded
                  width="160"
                  height="50"
                  style="#0568FD"
                  class="font-size-20"
              >
                <span style="color:#7B441F;">立即咨询</span>
              </v-btn>
            </v-card-actions> -->
          </v-card>
        </v-container>
      </v-img>
    </v-banner>  
    <!-- 产品特色 -->
    <v-card class="warp warp1" :flat="true" width="1200">
      <div class="public-title " style="margin-bottom:30px; margin-top:80px">
        <p>FEATURE OF PRODUCT</p>
        <div>
          <span>产品特色</span>
        </div>
      </div>
      <div class="con">
        <v-row no-gutters justify='space-between'>
          <v-col v-for="(info,index) in data.warp1.charList" :key="index" cols="12" sm="3" >
            <div class="box">
              <img :src='info.src' />
              <v-card-title class="title"> {{ info.title }} </v-card-title>
              <v-card-text class="text"> {{ info.content }} </v-card-text>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <!-- 数字博物馆产品模块 -->
    <v-card class="warp warp2" :flat="true" width="1200">
      <div class="public-title mt-12" style="margin-bottom:30px">
        <p>PRODUCT MODULE</p>
        <div>
          <span>数字博物馆产品模块</span>
        </div>
      </div>
      <v-tabs v-model="tab" centered color="#0084ff" class="g-tabs">
        <v-tab v-for="(v, k) in data.warp2.list" :key="k">
          <b>{{ v.tabTitle }}</b>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(v, k) in data.warp2.list" :key="k" :transition="false">
            <div class="box">
              <transition name="slide-fade" appear>
                <div class="left" v-show="tab == k"><img :src='v.img' /></div>
              </transition>
              <transition name="slide-fade-reverse" appear>
                <div class="right" v-show="tab == k">
                  <v-card-text class="title" v-text="v.lunTitle" />
                  <v-card-text class="tip" v-text="v.tip" />
                  <v-card-text class="text" v-for="(i, j) in v.contents" :key="j" v-text="i" />
                </div>
               </transition>
            </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <!-- 使用场景 -->
    <v-card class="warp warp4" :flat="true" width="1200">
      <div class="public-title" style="margin-bottom:40px;margin-top:70px">
        <p>SCENES TO BE USED</p>
        <div>
          <span>使用场景</span>
        </div>
      </div>
      <div class="con mb-15">
        <v-row>
          <v-col
            v-for="(n,i) in data.warp3.sceneList" :key="i" cols="3"
            class="d-flex child-flex"
          >
            <div class="box-img" :style="{backgroundImage: 'url('+n.src+')'}">
              <div>
                {{ n.title }}
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-app>
</template>

<script>
import contentData from '@/static/contentData.js'
let _this;
export default {
  name: "DigitalMuseum",
  data() {
    return {
      data: contentData.DigitalMuseum,
      productIndex: 0,
      bannerLoad: false,
      imgLoad: false,
      tab: 0,
    };
  },
  mounted() {
    _this = this;
    this.bannerLoad = true;
  },
  methods: {
    handleLoad: () => {
      _this.imgLoad = true;
      console.log("ewf")
    }
  }
}
</script>

<style lang="scss" scoped>
.public-title{
  height: 68px;
  margin-bottom: 0px;
  
}
.scope-service {
  background-color: #f9fafe;
}

.app-scenes {
  background-color: #f9fafe;

  ::v-deep .v-tabs-bar__content {
    border-bottom: 3px solid #dedfdf;
    display: flex;
    justify-content: center;

    .v-tabs-slider-wrapper {
      bottom: -2px;
    }

    .v-tab:not(.v-tab--active) {
      color: rgba(0, 0, 0, 1);
    }
  }
}
.warp {
  max-width: 1200px;
  margin: 0 auto;
}

.warp1 {
  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    position: relative;
    width: 333px;
    img {
      width: 120px;
      height: 120px;
      border-radius: 10px;
    }
    .title {
      font-size: 24px !important;  
      color: #333333;
    }
    .tip {
      font-size: 16px !important;
      line-height: 26px;
      color: #666;
    }
    .text {
      font-size: 16px;
      line-height: 30px;
      color: #666;
    }
  }
}

.warp2 {
  .box {
    margin: 35px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left {
    margin-right: 55px;
    img {
      width: 400px;
      height: 260px;
      border-radius: 30px;
    }
  }
  .right{
    width: 400px;
    text-align: start;
    //font-family: SourceHanSansCN-Regular;
    .v-card__text {
      padding: 0;
    }
    .title {
      font-size: 24px !important;  
      color: #333333;
      margin-bottom: 16px;
      font-weight: 500;
      line-height: 34px;
    }
    .tip {
      font-size: 16px !important;
      line-height: 26px;
      color: #666;
    }
    .text {
      font-size: 16px;
      line-height: 30px;
      color: #666;
    }
  }

}

.warp4 {
  .con {
    margin-top: 45px;
    .box-img {
      width: 240px;
      height: 160px;
      border-radius: 10px;
      background-size: cover;
      overflow: hidden;
      transition: all 0.3s ease-in;
      div {
        width: 100%;
        height: 100%;
        background-color:rgba(25, 26, 27, 0.5);
        color: #fff;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

</style>